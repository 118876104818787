import { React, Suspense, lazy, useState, useEffect} from "react";
import "./NavBar.css";
import Button from "@mui/material/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { styled } from '@mui/material/styles';
import TranslateIcon from '@mui/icons-material/Translate';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import axios from "axios";

const HamburgerDrawer = lazy(() => import('./HamburgerDrawer/HamburgerDrawer'));

const AntMenuItem = styled(MenuItem)({
	margin: '0 10px',
	'&.Mui-selected': {
		backgroundColor: '#7bc15f',
		color: 'white',
		'& .MuiListItemIcon-root': {
			color: 'white',
		},
		'&:hover': {
			backgroundColor: '#7bc15f',
		}
	},
	'&:hover': {
		backgroundColor: '#7bc15f',
		color: 'white',
		'& .MuiListItemIcon-root': {
			color: 'white',
		}
	}
});

const NavBar = ( { setCategory, setLanguage, defaultLanguage, navigate = null, showMenu = false, activeMenu = 'home' } ) => {

	const { t, i18n: { changeLanguage } } = useTranslation();
	const [open, setOpen] = useState(false);
	const [categories, setCategories] = useState([]);
	const [language, setLang] = useState(defaultLanguage);

	const getCategories = async (formData) => 
	{
		try 
		{
			const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/news_category?language=${language}`);
			setCategories(response.data.results);
			localStorage.setItem('categories',  JSON.stringify(response.data.results));
		} 
		catch (error) 
		{
			console.log(error);	
		}
	};

	useEffect(() => 
	{
		if(language)
		{
			getCategories();
		}
		
	},[language]);

	const handleListItemClick = (value) => {
		localStorage.setItem('language',value);
		document.cookie = "googtrans=/en/"+value;
		setLanguage(value);
		changeLanguage(value);
		setLang(value);
		setOpen(false);
	};

	const handleClose = (event, reason) => {
		setOpen(false);
	}
	const setLan = (lang) => {
		handleListItemClick(lang);
	}

	return (
		<div className="nav">
			<a href="https://utkrishi.com">
				<img
					style={{ cursor: "pointer" }}
					src="/Utkrishi Logo.png"
					alt="logo"
					className="logo"
					onClick={() => setCategory('')}
				/>
			</a>
			<div className="icon"></div>
			{
				showMenu
				?
				(
					<Suspense fallback={<div>Loading...</div>}>
						<HamburgerDrawer setCategory={setCategory} setLanguage={setLan} defaultLanguage={defaultLanguage}/>
					</Suspense>
				)
				:
				(<Button onClick={navigate}><ArrowBackIcon/></Button>)
			}
			<div className="menu">
				<MenuList style={{ display: 'flex' }}>
					<a href="https://www.utkrishi.com" style={{ textDecoration:'none', color:'inherit' }}>
						<AntMenuItem>
							<ListItemText>{ t('menu.Home') }</ListItemText>
						</AntMenuItem>
					</a>
					<NavLink to={'/'} style={{ textDecoration:'none', color:'inherit' }}>
						<AntMenuItem selected={activeMenu == 'home'}>
							<ListItemText>{ t('menu.News') }</ListItemText>
						</AntMenuItem>
					</NavLink>
					<NavLink to={'/schemes'} style={{ textDecoration:'none', color:'inherit' }}>
						<AntMenuItem selected={activeMenu == 'schemes'}>
							<ListItemText>{ t('menu.Schemes') }</ListItemText>
						</AntMenuItem>
					</NavLink>
					<a href="https://www.youtube.com/@utkrishi" style={{ textDecoration:'none', color:'inherit' }}>
						<AntMenuItem>
							<ListItemText>{ t('menu.Videos') }</ListItemText>
						</AntMenuItem>
					</a>
					<a href="https://loan.utkrishi.com" style={{ textDecoration:'none', color:'inherit' }}>
						<AntMenuItem>
							<ListItemText>{ t('menu.Loan') }</ListItemText>
						</AntMenuItem>
					</a>
					<a href="https://www.utkrishi.com/blog" style={{ textDecoration:'none', color:'inherit' }}>
						<AntMenuItem>
							<ListItemText>{ t('menu.Blogs') }</ListItemText>
						</AntMenuItem>
					</a>
					<a href="https://www.utkrishi.com/contact-us" style={{ textDecoration:'none', color:'inherit' }}>
						<AntMenuItem>
							<ListItemText>{ t('menu.Contact Us') }</ListItemText>
						</AntMenuItem>
					</a>
					<NavLink style={{ textDecoration:'none', color:'inherit' }} onClick={() => setOpen(true)}>
						<AntMenuItem>
							<ListItemIcon>
								<TranslateIcon />
							</ListItemIcon>
							<ListItemText>{ language == 'en' ? 'ENglish' : language == 'hi' ? 'हिन्दी' : 'मराठी' }</ListItemText>
						</AntMenuItem>
					</NavLink>
				</MenuList>
			</div>
			<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
				<DialogTitle id="simple-dialog-title">				
					{ t('menu.changeLanguage') }
				</DialogTitle>
				<List>
					<ListItem onClick={() => handleListItemClick('hi')} key={'हिन्दी'} style={{cursor: 'pointer' }}>
						<ListItemText primary={'हिन्दी'} />
					</ListItem>
					<ListItem onClick={() => handleListItemClick('mr')} key={'मराठी'} style={{cursor: 'pointer' }}>
						<ListItemText primary={'मराठी'} />
					</ListItem>
					<ListItem onClick={() => handleListItemClick('en')} key={'English'} style={{cursor: 'pointer' }}>
						<ListItemText primary={'English'} />
					</ListItem>
				</List>
			</Dialog>
		</div>
	);
};

export default NavBar;
