import React, { Suspense, lazy } from "react";
import { RouterProvider,createBrowserRouter, } from "react-router-dom";

import { Home } from "../pages/home/Home";
import PrivateRoute from "./util/PrivateRoutes";
import UserProtectedRoute from "./util/UserProtectedRoute";
import ScrollToTop from "./../pages/dashboard/scrollToTop";
import FpoList from "../components/dashboard/fpo/FpoList";
import FpoCreate from "../components/dashboard/fpo/FpoCreate";


const Login = lazy(() => import('../pages/login/Login'));
const ReadNews = lazy(() => import('../pages/read-news/ReadNews'));
const NotFound = lazy(() => import('../pages/not-found/NotFound'));
const SignIn = lazy(() => import('../pages/sign-in/SignIn'));
const SignUp = lazy(() => import('../pages/sign-up/SignUp'));

const Schemes = lazy(() => import('../pages/schemes/Schemes'));
const ReadScheme = lazy(() => import('../pages/read-scheme/ReadScheme'));

const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const CategoryList = lazy(() => import('../components/dashboard/news-category/CategoryList'));
const NewsList = lazy(() => import('../components/dashboard/news/NewsList'));
const CategoryCreate = lazy(() => import('../components/dashboard/news-category/CategoryCreate'));
const WriteArticleForm = lazy(() => import('../components/dashboard/news/WriteArticleForm'));
const AdsList = lazy(() => import('../components/dashboard/ads/AdsList'));
const AdsCreate = lazy(() => import('../components/dashboard/ads/AdsCreate'));
const CreateLoanCategory = lazy(() => import('../components/dashboard/loan-category/CreateLoanCategory'));
const LoanCategoryList = lazy(() => import('../components/dashboard/loan-category/LoanCategoryList'));
const CreateBank = lazy(() => import('../components/dashboard/banks/CreateBank'));
const BankList = lazy(() => import('../components/dashboard/banks/BankList'));

const CreateLoan = lazy(() => import('../components/dashboard/loans/CreateLoan'));
const LoanList = lazy(() => import('../components/dashboard/loans/LoanList'));
const LoanApplyList =  lazy(() => import("../components/dashboard/loan-apply/LoanApplyList"));

const AppRoutes = () => {
  const router = createBrowserRouter([
    {
      path: '/:title?',
      element: <Home />
    },
    {
      path: '/read/:title',
      element: <ReadNews />
    },
    {
      path: 'login',
      element: <Login />
    },
    {
      path: 'sign-in',
      element: <SignIn />
    },
    {
      path: 'sign-up',
      element: <SignUp />
    },
    {
      path: 'schemes',
      element: <UserProtectedRoute> <Schemes /> </UserProtectedRoute> 
    },
    {
      path: 'schemes/:slug',
      element: <UserProtectedRoute> <ReadScheme /> </UserProtectedRoute>
    },
    {
      path: 'admin',
      element: <PrivateRoute> <ScrollToTop /> <Dashboard /> </PrivateRoute>,
      children: [
        {
          path: 'news/category',
          element: <CategoryList />,
        },
        {
          path: 'news/category/create',
          element: <CategoryCreate />,
        },
        {
          path: 'news/category/edit/:id',
          element: <CategoryCreate />,
        },
        {
          path: 'news',
          element: <NewsList />,
        },
        {
          path: 'news/create',
          element: <WriteArticleForm />,
        },
        {
          path: 'news/edit/:id',
          element: <WriteArticleForm />,
        },
        {
          path: 'ads',
          element: <AdsList />,
        },
        {
          path: 'ads/create',
          element: <AdsCreate />,
        },
        {
          path: 'ads/edit/:id',
          element: <AdsCreate />,
        },
        {
          path: 'fpo',
          element: <FpoList />,
          },
          {
          path: 'fpo/create',
          element: <FpoCreate />,
          },
          {
          path: 'fpo/edit/:id',
          element: <FpoCreate />,
        },
        {
          path: 'loan/category',
          element: <LoanCategoryList />,
        },
        {
          path: 'loan/category/create',
          element: <CreateLoanCategory />,
        },
        {
          path: 'loan/category/edit/:id',
          element: <CreateLoanCategory />,
        },
        {
          path: 'bank',
          element: <BankList />,
        },
        {
          path: 'bank/create',
          element: <CreateBank />,
        },
        {
          path: 'bank/edit/:id',
          element: <CreateBank />,
        },
        {
          path: 'loan',
          element: <LoanList />,
        },
        {
          path: 'loan/create',
          element: <CreateLoan />,
        },
        {
          path: 'loan/edit/:id',
          element: <CreateLoan />,
        },
        {
          path: 'loan/response',
          element: <LoanApplyList />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default AppRoutes;
